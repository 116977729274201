@use "src/utils/styles/mediaQuery" as media;

.template {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  position: relative;
  width: 100%;
  height: 85vh;
  background-color: rgba(0, 0, 0, 50%);
  background-image: url("../../assets/image/main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    left: 80px;
    padding: 5px;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff;
    }
  }

  &__logo {
    width: 350px;

    @include media.media-breakpoint-down(mobile) {
      width: 80%;
    }
  }

  &__cover {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 50%);
  }

  &__main {
    font-size: 64px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    white-space: pre-wrap;
    margin-bottom: 30px;

    @include media.media-breakpoint-down(mobile) {
      font-size: 40px;
    }
  }

  &__sub {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    white-space: pre-wrap;

    @include media.media-breakpoint-down(mobile) {
      font-size: 16px;
    }
  }
}

.introduce {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 200px 0;

  &__phrase {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--main {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 20px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 40px;
      }
    }

    &--sub {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      line-height: 36px;

      @include media.media-breakpoint-down(mobile) {
        white-space: pre-wrap;
        font-size: 18px;
      }
    }
  }

  &__menu {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
    }

    &--item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 300px;
      height: 300px;
      border-radius: 100%;
      background-color: #222222;
      font-size: 40px;
      color: #ffffff;
      font-weight: 900;
      white-space: pre-wrap;
      text-align: center;
      margin: 20px 40px;

      @include media.media-breakpoint-down(mobile) {
        width: 31vw;
        height: 31vw;
        font-size: 18px;
        margin: 3px;
      }

      span {
        white-space: pre-wrap;
      }
    }
  }
}

.doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  &__title {
    font-size: 40px;
    font-weight: 700;
    margin: 50px 0;
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__wrap {
    position: relative;
    width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid #666666;
    box-sizing: border-box;

    &:last-child {
      border-bottom: 1px solid #666666;
    }

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      margin: 5px 0;
    }
  }

  &__career {
    width: 100%;
    height: 100%;
    display: flex;
    white-space: pre-wrap;
    color: #222222;
    font-size: 2rem;
    transition: 0.4s;
    padding: 0 10px;
    gap: 30px;
    box-sizing: border-box;

    @include media.media-breakpoint-down(mobile) {
      flex-direction: column;
    }

    &--top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      white-space: nowrap;
      gap: 5px;
    }

    &--name {
      font-size: 4rem;
      font-weight: 700;

      @include media.media-breakpoint-down(mobile) {
        font-size: 3rem;
      }
    }

    &--rank {
      font-size: 3.4rem;
      font-weight: 400;
      margin-right: 5px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 2.4rem;
      }
    }

    &--ul {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      white-space: pre-wrap;
      word-break: keep-all;
      list-style: none;
    }

    &--list {
      margin: 5px 0;
      color: #666666;

      &:first-child {
        color: #222222;
        font-weight: 700;
      }

      @include media.media-breakpoint-down(mobile) {
        font-size: 1.7rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &__sub {
    background-color: #000000;
  }

  &__name {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-bottom: 1px solid #dddddd;
    font-size: 16px;

    &--name {
      font-size: 28px;
      font-weight: 500;
    }
  }
}

.slide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 40px;
  font-weight: 900;
  margin: 100px;

  @include media.media-breakpoint-down(mobile) {
    font-size: 30px;
  }

  &__title {
    font-size: 50px;
    color: #995b09;

    @include media.media-breakpoint-down(mobile) {
      font-size: 40px;
    }
  }
}

.slider {
  width: 60vw;
  margin: 30px;

  @include media.media-breakpoint-down(mobile) {
    width: 100%;
  }

  img {
    height: 75vh;
    object-fit: scale-down;
    margin: 0 auto;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      height: 300px;
    }
  }
}

.map {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px;

  &__title {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;

    @include media.media-breakpoint-down(mobile) {
      font-size: 35px;
    }
  }

  &__parking {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: 50px 0 30px;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
    }

    &--title {
      font-size: 35px;
      font-weight: 700;
      margin: 20px;
      text-align: center;

      @include media.media-breakpoint-down(mobile) {
        white-space: pre-wrap;
      }
    }

    &--guide {
      font-size: 25px;
      text-align: center;
      color: #666666;
      white-space: pre-wrap;
      border: 2px solid #dddddd;
      padding: 40px 0;
      margin: 0 5px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 20px;
      }

      span {
        white-space: normal;

        @include media.media-breakpoint-down(mobile) {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.time {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  margin-bottom: 200px;

  @include media.media-breakpoint-down(mobile) {
    flex-direction: column;
    height: 80vh;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/image/treat.png");
    background-color: rgba(0 0 0 / 50%);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    z-index: -1;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 10px;
    font-size: 25px;

    &--day {
      display: flex;
      justify-content: center;
      width: 130px;
      padding: 5px 10px;
      margin-right: 10px;
      background-color: #ffffff;
      color: #000000;
      font-weight: 700;
      border-radius: 20px;
      font-size: 25px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 20px;
      }
    }

    &--time {
      font-weight: 500;

      @include media.media-breakpoint-down(mobile) {
        font-size: 15px;
      }
    }
  }

  &__left {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #ffffff;
    font-size: 30px;
    margin: 20px;

    @include media.media-breakpoint-down(mobile) {
      white-space: pre-wrap;
      font-size: 20px;
    }

    &--title {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 10px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 40px;
      }
    }

    &--phone {
      margin-top: 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      font-weight: 700;
      color: #81bef7;
      fill: #81bef7;
      text-decoration: none;
      font-size: 40px;

      @include media.media-breakpoint-down(mobile) {
        font-size: 30px;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.treatment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;

  @include media.media-breakpoint-down(mobile) {
    flex-direction: column;
  }

  &__phrase {
    display: flex;
    align-items: center;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      justify-content: center;
    }

    &--t1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      background-color: rgba(0 0 0 / 50%);
      background-position: center;
      background-blend-mode: multiply;
      background-size: cover;
      border-radius: 10px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      margin: 0 10px;

      @include media.media-breakpoint-down(mobile) {
        width: 50vw;
        height: 50vw;
        border-radius: 0;
        margin: 0;
        border: 1px solid #ffffff;
        border-collapse: collapse;
      }
      background-image: url("../../assets/image/treatment/chuna.png");
    }

    &--t2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      background-color: rgba(0 0 0 / 50%);
      background-position: center;
      background-blend-mode: multiply;
      background-size: cover;
      border-radius: 10px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      margin: 0 10px;

      @include media.media-breakpoint-down(mobile) {
        width: 50vw;
        height: 50vw;
        border-radius: 0;
        margin: 0;
        border: 1px solid #ffffff;
        border-collapse: collapse;
      }
      background-image: url("../../assets/image/treatment/medicine.png");
    }

    &--t3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      background-color: rgba(0 0 0 / 50%);
      background-position: center;
      background-blend-mode: multiply;
      background-size: cover;
      border-radius: 10px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      margin: 0 10px;

      @include media.media-breakpoint-down(mobile) {
        width: 50vw;
        height: 50vw;
        border-radius: 0;
        margin: 0;
        border: 1px solid #ffffff;
        border-collapse: collapse;
      }
      background-image: url("../../assets/image/treatment/car.png");
    }

    &--t4 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      background-color: rgba(0 0 0 / 50%);
      background-position: center;
      background-blend-mode: multiply;
      background-size: cover;
      border-radius: 10px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      margin: 0 10px;

      @include media.media-breakpoint-down(mobile) {
        width: 50vw;
        height: 50vw;
        border-radius: 0;
        margin: 0;
        border: 1px solid #ffffff;
        border-collapse: collapse;
      }
      background-image: url("../../assets/image/treatment/treat.png");
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #000000;
  color: #ffffff;

  @include media.media-breakpoint-down(mobile) {
    flex-direction: column-reverse;
  }

  &__info {
    width: 30%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      font-size: 11px;
      white-space: pre-wrap;
    }

    &--title {
      font-weight: 700;
      font-size: 18px;
    }

    &--phone {
      margin-right: 10px;
    }

    &--ceo {
      margin-left: 10px;
    }
  }

  &__logo {
    width: 30%;
    display: flex;
    justify-content: center;

    img {
      height: 50px;
      margin-right: 10px;

      @include media.media-breakpoint-down(mobile) {
        height: 40px;
        margin-top: 20px;
      }
    }
  }

  &__phone {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    fill: #ffffff;

    @include media.media-breakpoint-down(mobile) {
      width: 100%;
      margin: 20px 0;
      font-size: 30px;
    }

    svg {
      width: 40px;
      height: 40px;

      @include media.media-breakpoint-down(mobile) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.sideMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 999;

  @include media.media-breakpoint-down(mobile) {
    top: 80%;
  }

  &__map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background-color: #1ddb16;
    border-radius: 100px;
  }

  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background-color: #81bef7;
    border-radius: 100px;
  }

  &__register {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background-color: #b85d02;
    border-radius: 100px;
  }
}

.disabled {
  display: none;
}

.next {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  color: #000000;
  font-weight: 900;
  font-size: 50px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    color: #333333;
  }

  @include media.media-breakpoint-down(mobile) {
    font-size: 40px;
    height: 300px;
  }
}

.prev {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  color: #000000;
  font-weight: 900;
  font-size: 50px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    color: #333333;
  }

  @include media.media-breakpoint-down(mobile) {
    font-size: 40px;
    height: 300px;
  }
}
